<template>

  <MainHeader headline='Meine Bewerbungen' text="Verwalten Sie Ihre Bewerbungen"/>

  <div class="container" v-if="currentApplications.length > 0 || availableApplications.length > 0 || closedApplications.length > 0">

    <div class="row mt-5 mb-5">

      <div class="col-12">


        <template v-for="current in currentApplications">
        <div @click="goTo(current);" class="card bl" :class="(current.use_special_order === 'yes') ? '' : 'mb-10'" v-if="current && current.name" :style="{borderLeftColor: current.color}">

          <div class="card-body">

            <div class="row h-100">



              <div class="col-md-8 col-lg-8">

                <h4>{{current.name}}</h4>
                <p class="mb-0"><strong>{{$t('application.phase')}}</strong></p>
                <p class="mb-4">{{$filters.formatDate(current.published_from)}} - {{$filters.formatDate(current.published_to)}}</p>
                <p class="text-muted"><i>Bewerbung gestartet: {{$filters.formatDate(current.request_start)}}</i></p>



              </div>

              <div class="col-md-4 col-lg-4 justify-content-center align-self-center text-end">

                <button class="btn btn-secondary text-uppercase">
                  {{$t('application.state.'+current.state)}}
                </button>

              </div>

            </div>

          </div>


        </div>
          <div class="specialOrderInfo mb-10" v-if="current.use_special_order === 'yes'">
            <p class="mb-0" v-if="current.state === 'open' || current.state === 'active' && !current.specialOrder">Sie sind berechtigt einen Antrag auf Zulassung im Rahmen des Pilotprojektes "Alternativer Zulassungsweg" zu stellen. <span style="color:#21AE80; cursor: pointer" @click="openSpecialPopup(current)">Klicken Sie hier.</span></p>
            <p class="mb-0" v-else-if="current.state === 'open' || current.state === 'active'">Sie haben einen Sonderantrag eingereicht.</p>
          </div>
        </template>
        <template v-for="current in closedApplications">
          <div  @click="goTo(current, 'closed');"  :class="(current.use_special_order === 'yes') ? '' : 'mb-10'"  class="card bl" v-if="current" :style="{borderLeftColor: current.color}">

            <div class="card-body">

              <div class="row h-100">

                <div class="col-md-2 col-lg-1 justify-content-center align-self-center" >

                  <font-awesome-icon :icon="['far', 'file']" size="2x"/>

                </div>

                <div class="col-md-6 col-lg-7">

                  <h4>{{current.name}}</h4>
                  <p class="mb-0"><strong>{{$t('application.phase')}}</strong></p>
                  <p class="mb-4">{{$filters.formatDate(current.published_from)}} - {{$filters.formatDate(current.published_to)}}</p>
                  <p class="text-muted mb-0"><i>Bewerbung gestartet: {{$filters.formatDateTime(current.request_start)}} </i></p>
                  <p class="text-muted" v-if="current.request_end"><i>Bewerbung eingereicht: <template v-if="current.request_end.endsWith('00:00:00')"> {{$filters.formatDate(current.request_end)}}</template><template v-else>
                    {{$filters.formatDateTime(current.request_end)}}
                  </template></i></p>
                </div>

                <div class="col-md-4 col-lg-4 justify-content-center align-self-center text-end">



                  <button class="btn btn-secondary text-uppercase" :class="'state_'+current.state">
                    <template v-if="!checkEndDate(current.published_to)">
                      In Bearbeitung
                    </template>
                    <template v-else>{{$t('application.state.'+current.state)}}</template>
                  </button>

                </div>

              </div>

            </div>

          </div>
          <div class="specialOrderInfo mb-10" v-if="current.use_special_order === 'yes'">
            <p class="mb-0" v-if="current.state === 'open' || current.state === 'active' && !current.specialOrder">Sie sind berechtigt einen Antrag auf Zulassung im Rahmen des Pilotprojektes "Alternativer Zulassungsweg" zu stellen. <span style="color:#21AE80; cursor: pointer" @click="openSpecialPopup(current)">Klicken Sie hier.</span></p>
            <p class="mb-0" v-else-if="current.state === 'open' || current.state === 'active'">Sie haben einen Sonderantrag eingereicht.</p>
          </div>
        </template>
        <div v-if="availableApplications && Object.keys(availableApplications).length > 0 && !currentApplication">
          <h3>{{$t('application.pleaseChoose')}}</h3>
          <p class="pt-4 pb-4">Beachten Sie, dass die Auswahl nicht mehr rückgängig gemacht werden kann. Sobald Sie eine Bewerbung abgeschlossen haben, ist es jedoch möglich, eine weitere Lizenzstufe für die Bewerbung auszuwählen und zu bearbeiten.
          </p>

          <div class="row appGridLic">
            <div class="col-lg-3"  v-for="ap in availableApplications">
          <div class="card bl mb-4 p-4" @click="chooseApplication(ap)" :style="{borderLeftColor: ap.color}">
             <h4>{{ap.name}}</h4>

             <p class="mb-0"><strong>{{$t('application.phase')}}</strong></p>
             <p class="">{{$filters.formatDate(ap.published_from)}} - {{$filters.formatDate(ap.published_to)}}</p>
            <template v-if="(ap.request_licenses_formatted && ap.request_licenses_formatted.length > 0 ) || (ap.request_licenses2_formatted && ap.request_licenses2_formatted.length > 0 )">
              <p class="mb-0" style="font-size: 13px;"><strong>Voraussetzungen</strong></p>
              <div style="font-size: 13px;">
               <p class="mb-0" v-if="ap.request_licenses_formatted.length > 1">Mindestens eine der folgenden Lizenzen:</p>
                <p class="mb-0" v-else>Die folgende Lizenz:</p>
              <span class="mb-0" >{{ap.request_licenses_formatted.join(', ')}}</span>
              <template v-if="ap.request_licenses2_formatted && ap.request_licenses2_formatted.length > 0">
                <br>
                <span class="mb-0" v-if="ap.request_licenses2_formatted.length > 1">und mindestens eine der folgenden Lizenzen:</span>
                <span class="mb-0" v-else>und die folgende Lizenz:</span>
                <br>
                <span class="mb-0">{{ap.request_licenses2_formatted.join(', ')}}</span>

              </template></div>
            </template>
          </div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <Modal v-if="specialOrderApplication">
      <div class="modal specialOrder fade show " tabindex="-1"
           aria-hidden="true" style="display: block;">
        <div class="modal-dialog ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" v-if="specialOrderApplication.special_order_headline && specialOrderApplication.special_order_headline !== ''">{{ specialOrderApplication.special_order_headline }}</h5>
              <h5 class="modal-title" v-else>Sonderantrag für {{ specialOrderApplication.name }}</h5>
              <button type="button" class="btn-close" @click="specialOrderApplication = null;"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div v-if="specialOrderApplication.special_order_body && specialOrderApplication.special_order_body !== ''" v-html="specialOrderApplication.special_order_body"></div>

                  <a v-if="specialOrderApplication.special_order_mime" :href="'data:'+specialOrderApplication.special_order_mime+';base64,'+specialOrderApplication.special_order_src" download="sonderantrag.pdf" class="btn btn-outline-primary me-2 mb-4 btn-sm">Vorlage herunterladen</a>

                  <UploadComponent :specialOrderApplication="specialOrderApplication" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
  <div class="container" v-else>
    <p class="pt-4">Aktuell liegen keine Bewerbungsmöglichkeiten zur Auswahl vor!</p>
  </div>
</template>

<script>

import MainHeader from '@/components/MainHeader'
import UploadComponent from '@/components/UploadComponent'
import axios from "axios";
import {mapGetters, mapActions} from 'vuex';
import {useToast} from 'vue-toastification';
export default {
  name: 'ApplicationsView',

  data() {
    return {
      specialOrderApplication: null,
      currentApplication: null,
      currentApplications: [],
      availableApplications: [],
      closedApplications: [],
    }
  },
  computed: {
    ...mapGetters(["getApiUrl"])
  },
  components: {
    MainHeader, UploadComponent
  },
  methods: {
    ...mapActions(["handleApiError", "handleApiResponse"]),
    openSpecialPopup(ap) {
      this.specialOrderApplication = ap;
      // add window event listener triggerReloadTask
      window.addEventListener('triggerReloadTask', () => {
        this.specialOrderApplication = null;
        this.getCurrentApplicationOrOverview();
      });

    },
    checkEndDate(date) {
      // if date (YYYY-mm-dd HH:ii:ss is in the past, return true
      let now = new Date();
      let endDate = new Date(date);
      let checker = endDate < now;
      console.log(checker);
      return checker;

    },
    goTo(ap, type) {
      const toast = useToast();
      console.log(this.checkEndDate(ap.published_to));
      if (this.checkEndDate(ap.published_to) === false && type === 'closed') {
        toast.error("Die Bewerbungsphase läuft noch!");
        return;
      }

      if(ap.state !== 'checking' && ap.state !== 'progress' && ap.state !== 'discus' && ap.state !== 'declined_user'){



      this.$store.state.currentProgress = 1;
      if (ap.state === 'active') {

        this.$store.commit("setCurrentApplication", ap);
        this.$store.commit("setClosedState", false);
        this.$router.push({ name: 'ApplicationDetails' });
      }
      else {
        this.$store.commit("setCurrentApplication", ap);
        this.$store.commit("setClosedState", true);
        this.$router.push({ name: 'ApplicationDetails' });
      }
      } else {
        toast.error("Die Bewerbung ist nicht freigeschaltet!");
      }
    },
    chooseApplication(ap) {
      this.$confirm("Bitte bestätigen!", "Sie möchten sich für die "+ap.name+ " bewerben?", "question", {cancelButtonText: "Nein, doch nicht!", confirmButtonColor:"#21AE80"}).then(() => {

        const headers = {"crmAuth":localStorage.getItem("dfbToken")};
        axios.post(this.getApiUrl + 'plugins/dfb/chooseApplication', ap, {headers}).then((response) => {
          let shortReport = response.data.result;
          if (shortReport.success === true) {

            if (shortReport.currentApplication && shortReport.currentApplication.length) {
              this.currentApplications = shortReport.currentApplication;
              let a;
              let ap;
              for(a in shortReport.currentApplication) {
                ap = shortReport.currentApplication[a];
                if (ap.state === 'active') {
                  this.currentApplication = ap;
                  this.$store.commit("setCurrentApplication", ap);
                }
              }
            }
            else {
              this.currentApplication = null;
              this.$store.commit("setCurrentApplication", null);
            }

            this.availableApplications = shortReport.availableApplications;
            this.closedApplications = shortReport.closedApplications;
          }
          else {
            this.handleApiResponse(response);
          }
        }).catch((e) => {
          this.handleApiError(e);
        });


      }).catch(() => {
        this.$log.debug("no");
      });
    },
    getCurrentApplicationOrOverview() {

      const headers = {"crmAuth":localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'plugins/dfb/currentApplicationOrOverview', {headers}).then((response) => {
        let shortReport = response.data.result;
        if (shortReport.success === true) {

          if (shortReport.currentApplication && shortReport.currentApplication.length) {
            this.currentApplications = shortReport.currentApplication;
            let a;
            let ap;
            for(a in shortReport.currentApplication) {
              ap = shortReport.currentApplication[a];
              if (ap.state === 'active') {
                this.currentApplication = ap;
                this.$store.commit("setCurrentApplication", ap);
              }
            }
          }
          else {
            this.currentApplication = null;
            this.$store.commit("setCurrentApplication", null);
          }

          this.availableApplications = shortReport.availableApplications;
          this.closedApplications = shortReport.closedApplications;
        }
        else {
          this.handleApiResponse(response);
        }
      }).catch((e) => {
        this.handleApiError(e);
      });


    }
  },
  created() {

    this.getCurrentApplicationOrOverview();

  }

}
</script>

<style lang="scss">


.text-uppercase.btn-secondary {
  &.state_declined_01, &.state_declined_02 , &.state_declined_03 , &.state_declined_04 , &.state_declined_05 {
    background-color: #F44336;
    border-color: #F44336;
    &:hover {
      background-color: darken(#F44336, 20%);
      border-color: darken(#F44336, 20%);
    }
  }
  &.declined, &.declined_user {
    background-color: #656362;
    border-color: #656362;
    &:hover {
      background-color: darken(#656362, 20%);
      border-color: darken(#656362, 20%);
    }
  }
}

.card {

  border: none;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .08);
  border-radius: 0;
 &.bl {
   cursor: pointer;
   border-left-width: 10px;
   border-left-style: solid;
   transition: none !important;
   padding-left: 24px !important;
   &:hover {
     padding-left: 14px !important;
     border-left-width:20px;
   }
 }
}
.row.appGridLic{
  .col-lg-3{
    margin-bottom: 24px;
    .bl{
      height: 100%;
    }
  }
}
</style>
