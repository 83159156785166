<template>
<div class="container">

  <div class="row">
    <div class="col-md-6"><h3>Profildaten</h3>
      <div class="customerData">
        <div class="table-responsive">
        <table class="table w100">
          <tr>
            <th style="width: 100%" class="align-top d-none d-lg-block ">Name</th>
            <td class="align-top">{{customer.c_firstname}} {{customer.c_lastname}}</td>
          </tr>
          <tr>
            <th class="align-top d-none d-lg-block ">Anschrift</th>
            <td class="align-top">{{customer.c_street}}<br>{{customer.c_zipcode}} {{customer.c_city}}</td>
          </tr>
          <tr>
            <th class="align-top d-none d-lg-block ">Geburtsdatum</th>
            <td class="align-top">{{$filters.formatDate(customer.c_birthday)}}</td>
          </tr>
          <tr>
            <th class="align-top d-none d-lg-block ">Geburtsort</th>
            <td class="align-top">{{customer.birthday_city}}</td>
          </tr>
          <tr>
            <th class="align-top d-none d-lg-block ">Email</th>
            <td class="align-top"> {{customer.c_email}}</td>
          </tr>
          <tr>
            <th class="align-top d-none d-lg-block ">Handynummer</th>
            <td class="align-top"> {{customer.c_handy}}</td>
          </tr>
        </table>

    </div>
      </div>

      <div class="customerData" style="margin-top: 24px;" v-if="customer.custom_fields">
        <h3>Aktuelle Trainerstation</h3>
        <div class="table-responsive">
          <table class="table w100">
            <tr>
              <th  class="align-top d-none d-lg-block ">Mannschaft</th>
              <td class="align-top">{{customer.custom_fields.mannschaft}}</td>
            </tr>
            <tr>
              <th class="align-top d-none d-lg-block ">Liga</th>
              <td class="align-top">{{customer.custom_fields.liga}}</td>
            </tr>
            <tr>
              <th class="align-top d-none d-lg-block ">Altersbereich</th>
              <td class="align-top">{{customer.custom_fields.altersbereich}}</td>
            </tr>
            <tr>
              <th class="align-top d-none d-lg-block ">Aufstieg</th>
              <td class="align-top"><template v-if="customer.custom_fields.aufstieg">Ja</template>
                <template v-else>Nein</template>
              </td>
            </tr>
            <template v-if="customer.custom_fields.aufstieg">


              <tr>
                <th class="align-top d-none d-lg-block ">Aufstieg von Liga</th>
                <td class="align-top">{{ customer.custom_fields.aufstiegVonLiga }}</td>
              </tr>
              <tr>
                <th class="align-top d-none d-lg-block ">Aufstieg in Liga</th>
                <td class="align-top">{{ customer.custom_fields.aufstiegInLiga }}</td>
              </tr>
              <tr>
                <th class="align-top d-none d-lg-block ">Aufstieg Verein</th>
                <td class="align-top">{{ customer.custom_fields.aufstiegVerein }}</td>
              </tr>
              <tr>
                <th class="align-top d-none d-lg-block ">Aufstieg Zeitpunkt</th>
                <td class="align-top">{{ $filters.formatDate(customer.custom_fields.aufstiegWann) }}</td>
              </tr>
            </template>
          </table>
        </div>
      </div>
      <router-link :to="{name:'profile'}" class="btn btn-primary mb-4">Profil bearbeiten</router-link>
    </div>
    <div class="col-md-6" v-if="getCurrentApplication.use_questions !== 'no' && getCurrentApplication.use_points !== 'no'"><h3>Punktzahlen</h3>

     <template v-if="getCurrentApplication.state !== 'active' && getCurrentApplication.state !== 'progress' && getCurrentApplication.state !== 'checking' && getCurrentApplication.state !== 'declined' && getCurrentApplication.state !== 'declined_user'">
       <div class="counterData detailTable mb-3" v-if="counters && options">
         <div class="inline">
           <table class="table">
             <tbody>
             <tr>
               <td style="padding:0; font-weight: bold" class="align-top">Titel</td>
               <td style="padding:0; font-weight: bold" class="align-top">Aktuell</td>
               <td style="padding:0; font-weight: bold" class="align-top">Eingereicht</td>
             </tr></tbody>
           </table>
         </div>
         <div class="inline">
           <table class="table">
             <tr>
               <td class="align-top">{{options.vereinstaetigkeit.infos.title}}</td>
               <td class="align-top"><template v-if="counters.vereinstaetigkeit">{{counters.vereinstaetigkeit.groupSum}}</template><template v-else>0</template></td>
               <td class="align-top"><span class="oldValues"><template  v-if="counters.vereinstaetigkeit && counters.vereinstaetigkeit.oldPoints">{{counters.vereinstaetigkeit.oldPoints}}</template><template v-else>0</template> </span></td>
             </tr>
           </table>
         </div>
         <div class="inline">
           <table class="table">
             <tr>
               <td class="align-top">{{options.verbandstaetigkeit.infos.title}}</td>
               <td class="align-top"> <template v-if="counters.verbandstaetigkeit">{{counters.verbandstaetigkeit.groupSum}}</template><template v-else>0</template></td>
               <td class="align-top"><span class="oldValues"><template  v-if="counters.verbandstaetigkeit && counters.verbandstaetigkeit.oldPoints">{{counters.verbandstaetigkeit.oldPoints}}</template><template v-else>0</template> </span></td>

             </tr>
           </table>
         </div>
         <div class="inline">
           <table class="table">
             <tr>
               <td class="align-top">{{options.internationaltrainer.infos.title}}</td>
               <td class="align-top"><template v-if="counters.internationaltrainer">{{counters.internationaltrainer.groupSum}}</template></td>
               <td class="align-top"><span class="oldValues"><template  v-if="counters.internationaltrainer && counters.internationaltrainer.oldPoints">{{counters.internationaltrainer.oldPoints}}</template><template v-else>0</template> </span></td>

             </tr>
           </table>
         </div>
         <hr>
         <div class="inline" v-if="counters.vereinsspieler">
           <table class="table">
             <tr>
               <td class="align-top">Spieler*in Erfahrung: nationale Ebene</td>
               <td class="align-top"> {{counters.vereinsspieler.groupSum + counters.nationalspieler.groupSum}}</td>
               <td class="align-top"><span class="oldValues"><template  v-if="counters.vereinsspieler && counters.vereinsspieler.oldPoints">{{counters.vereinsspieler.oldPoints}}</template><template v-else>0</template> </span></td>

             </tr>
           </table>
         </div>
         <div class="inline">
           <table class="table">
             <tr>
               <td class="align-top">{{options.internationalspieler.infos.title}}</td>
               <td class="align-top"><template v-if="counters.internationalspieler">{{counters.internationalspieler.groupSum}}</template></td>
               <td class="align-top"><span class="oldValues"><template  v-if="counters.internationalspieler && counters.internationalspieler.oldPoints">{{counters.internationalspieler.oldPoints}}</template><template v-else>0</template> </span></td>

             </tr>
           </table>
         </div>
         <div class="inline"  v-if="counters.groupSums !== counters.complete">
           <table class="table">
             <tr>
               <td class="align-top">Nicht anrechenbare Spieler*in Erfahrung (gemäß AO)</td>
               <td class="align-top">{{counters.diffSum}}</td>
               <td class="align-top"><span class="oldValues"><template>0</template></span></td>

             </tr>
           </table>
         </div>
         <hr>
         <div class="inline">
           <table class="table">
             <tr>
               <td class="align-top">{{options.ausbildung.infos.title}}</td>
               <td class="align-top"> <template v-if="counters.ausbildung">{{counters.ausbildung.groupSum}}</template><template v-else>0</template></td>
               <td class="align-top"><span class="oldValues"><template  v-if="counters.ausbildung && counters.ausbildung.oldPoints">{{counters.ausbildung.oldPoints}}</template><template v-else>0</template> </span></td>

             </tr>
           </table>
         </div>
         <div class="inline" v-if="options.trainerausbildung">
           <table class="table">
             <tr>
               <td class="align-top">{{options.trainerausbildung.infos.title}}</td>
               <td class="align-top"> <template v-if="counters.trainerausbildung">{{counters.trainerausbildung.groupSum}}</template><template v-else>0</template></td>
               <td class="align-top"><span class="oldValues"><template  v-if="counters.trainerausbildung && counters.trainerausbildung.oldPoints">{{counters.trainerausbildung.oldPoints}}</template><template v-else>0</template> </span></td>

             </tr>
           </table>
         </div><div class="inline">
         <table class="table">
           <tr>
             <td class="align-top">{{options.weiterbildung.infos.title}}</td>
             <td class="align-top"> <template v-if="counters.weiterbildung">{{counters.weiterbildung.groupSum}}</template><template v-else>0</template></td>
             <td class="align-top"><span class="oldValues"><template  v-if="counters.weiterbildung && counters.weiterbildung.oldPoints">{{counters.weiterbildung.oldPoints}}</template><template v-else>0</template> </span></td>

           </tr>
         </table>
       </div>
         <hr>
         <div class="complete">
           <table class="table">
             <tr>
               <th class="align-top">Gesamtpunktzahl</th>
               <td class="align-top"><strong style="padding: 0">{{counters.complete}}</strong></td>

               <td class="align-top"> <strong style="padding: 0">{{counters.oldComplete}}</strong></td>
             </tr>
           </table>

         </div>
       </div>
     </template>
<template v-else>
      <div class="counterData mb-3" v-if="counters && options">

        <div class="inline">
          <table class="table">
            <tr>
              <td class="align-top">{{options.vereinstaetigkeit.infos.title}}</td>
              <td class="float-end align-top"><template v-if="counters.vereinstaetigkeit">{{counters.vereinstaetigkeit.points}}</template><template v-else>0</template></td>
            </tr>
          </table>
        </div>
        <div class="inline">
          <table class="table">
            <tr>
              <td class="align-top">{{options.verbandstaetigkeit.infos.title}}</td>
              <td class="float-end align-top"> <template v-if="counters.verbandstaetigkeit">{{counters.verbandstaetigkeit.points}}</template><template v-else>0</template></td>
            </tr>
          </table>
        </div>
        <div class="inline">
          <table class="table">
            <tr>
              <td class="align-top">{{options.internationaltrainer.infos.title}}</td>
              <td class="float-end align-top">wird vom DFB festgelegt</td>
            </tr>
          </table>
        </div>
        <hr>
        <div class="inline" v-if="counters.vereinsspieler">
          <table class="table">
            <tr>
              <td class="align-top">Spieler*in Erfahrung: nationale Ebene</td>
              <td class="float-end align-top"> {{counters.vereinsspieler.points + counters.nationalspieler.points}}</td>
            </tr>
          </table>
        </div>
        <div class="inline" v-if="options.internationalspieler" >
          <table class="table">
            <tr>
              <td class="align-top">{{options.internationalspieler.infos.title}}</td>
              <td class="float-end align-top">wird vom DFB festgelegt</td>
            </tr>
          </table>
        </div>
        <hr>

        <div class="inline" v-if="options.ausbildung">
          <table class="table">
            <tr>
              <td class="align-top">{{options.ausbildung.infos.title}}</td>
              <td class="float-end align-top"> <template v-if="counters.ausbildung">{{counters.ausbildung.points}}</template><template v-else>0</template></td>
            </tr>
          </table>
        </div>
        <div class="inline" v-if="options.trainerausbildung">
          <table class="table">
            <tr>
              <td class="align-top">{{options.trainerausbildung.infos.title}}</td>
              <td class="float-end align-top"> <template v-if="counters.trainerausbildung">{{counters.trainerausbildung.points}}</template><template v-else>0</template></td>
            </tr>
          </table>
        </div>
        <div class="inline" v-if="options.weiterbildung">
          <table class="table">
            <tr>
              <td class="align-top">{{options.weiterbildung.infos.title}}</td>
              <td class="float-end align-top"> <template v-if="counters.weiterbildung">{{counters.weiterbildung.points}}</template><template v-else>0</template></td>
            </tr>
          </table>
        </div>
        <hr>
        <div class="complete">
          <table class="table">
            <tr>
              <th class="align-top">Gesamtpunktzahl</th>
              <td class="float-end align-top"> <strong style="padding: 0">{{counters.complete}}</strong></td>
            </tr>
          </table>
        </div>
      </div>
</template>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
.customerData th, .customerData td {
  font-size: 14px;
}
.detailTable .table {
  td {
    font-size: 14px;
    text-align: right;
    width: 15%;
    color: #000;
    &:last-child {
      color: #999;
    }
    &:first-child {
      text-align: left;
      width: 70%;
    }
  }
}
</style>
<script>
import {mapGetters} from 'vuex';
export default {

  name: 'Profile',
  props: {

    customer: {},
    counters: {},
    options: {},

  },
  data() {
    return {
      complete: 0
    }
  },
  computed: {
    ...mapGetters(['getCurrentApplication']),
  },
  created() {


    let a;
    let c;
    for (a in this.counters) {
      c = this.counters[a];
      this.complete += c.points;
    }
    console.log("tasiafasf", this.options);
    console.log("tasiafasf", this.counters);

  }

}
</script>
