<template>
  <template v-if="!options ||  !activeAnswers">
    <preloader />

  </template>
  <template v-else>
  <div class="row"  v-if="calcTypes && calcTypes.vereinstaetigkeit && options && options.vereinstaetigkeit">
    <div class="col-12">
      <div class="row">
        <div class="col-md-6"><h3 class="mb-3">{{ options.vereinstaetigkeit.infos.title }}</h3>

        </div>
        <div class="col-md-6">
          <button type="button" @click="showInput = 1" class="btn btn-secondary float-end mb-3" v-if="$store.state.currentApplicationClosedState === false && showInput === 0">
            <font-awesome-icon :icon="['far', 'plus']"/>
            <span class="ms-3">Station hinzufügen</span></button>
        </div>
      </div>
      <Modal class="inputBlock mb-3" v-if="showInput === 1">
        <div class="container">
          <div class="card p-lg-5 p-3">
            <h2 class="mb-4">{{ options.vereinstaetigkeit.infos.title }} hinzufügen</h2>
            <div class="description mb-4" v-html="options.vereinstaetigkeit.infos.description"></div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="form-floating mb-3">
                  <input type="text" v-model="values.verein" class="form-control" placeholder="Verein">
                  <label for="floatingInput">Verein</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" v-model="values.altersklasse" class="form-control" placeholder="Altersklasse">
                  <label for="floatingInput">Altersklasse</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" v-model="values.liga" class="form-control" placeholder="Liga">
                  <label for="floatingInput">Liga</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="date" :max="$store.state.maxDateValue" v-model="values.dateStart" class="form-control" placeholder="Beginn">
                  <label for="floatingInput">Beginn</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="date" :max="$store.state.maxDateValue" v-model="values.dateEnd" class="form-control" placeholder="Ende">
                  <label for="floatingInput">Ende</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="number" v-model="values.saisons" class="form-control" placeholder="Saisons" min="0" step="0.50" max="100">
                  <label for="floatingInput">Saisons</label>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="form-floating mb-3">
                  <perfect-scrollbar>
                  <div v-for="(option, key) in options.vereinstaetigkeit.options" v-bind:key="key">
                    <template v-if="option.is_headline === '1'">
                      <label class="labelHeadline"
                             style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                             :for="'option'+key">{{ option.question }}</label>
                    </template>
                    <template v-else>
                    <input :value="option.id" v-model="values.answer_id" type="radio" class="btn-check" :id="'option'+key"
                           autocomplete="off">
                    <label class="btn btn-outline-primary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key">{{ option.question }}</label>
                    </template>
                  </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-6">
                <button class="btn btn-danger mb-3" type="button" @click="showInput = 0">Abbrechen</button>
              </div>
              <div class="col-6">
                <button   v-if="values.answer_id && values.saisons && values.dateEnd && values.dateStart && values.liga && values.verein && values.altersklasse" @click="postValues('vereinstaetigkeit', values); showInput = 0;" type="button"
                        class="btn btn-secondary float-end mb-3">Speichern
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div class="table-responsive">
        <table class="table table-striped table-values">
          <thead v-if="activeAnswers.vereinstaetigkeit">
          <tr> <th class="del"></th> <th  class="begin-end">Beginn</th>
            <th  class="begin-end">Ende</th>
            <th>Verein</th>
            <th>Altersklasse</th>
            <th>Liga</th>
            <th>Station</th>
            <th>Saisons</th>
            <th class="text-center" style="width:130px">Nachweis</th>
            <th class="points-end"  v-if="getCurrentApplication && getCurrentApplication.use_points === 'yes'">Punkte</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="entry in activeAnswers.vereinstaetigkeit.items" v-if="activeAnswers.vereinstaetigkeit">
            <td>
              <span v-if="$store.state.currentApplicationClosedState === false" class="delEntry btn btn-small btn-danger" @click='delEntry(entry.id)'>X</span>
              <EntryComment :entry="entry" />
            </td>

            <td>{{ $filters.formatDate(entry.dateStart) }}</td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verein }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td><template v-if="entry.liga !== '-- Gelöscht --'">{{ entry.liga }}</template></td>
            <td>{{entry.question}}</td>

            <td>{{ entry.saisons }}</td>
            <td>
              <template v-if="$store.state.currentApplicationClosedState === false && calcTypes && calcTypes.vereinstaetigkeit && calcTypes.vereinstaetigkeit.nachweis_erforderlich === 'yes'">
                <button @click="startedUpload = entry.id" class="btn w-100" :class="(entry.nachweis && entry.nachweis.length > 0) ? 'btn-warning': 'btn-danger'"><font-awesome-icon style="cursor: pointer" :icon="['far', 'plus']"/></button>                <template  v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span v-else> {{index+1}}</span>
                  </button>
                </template>
              </template>
            </td>
            <td  v-if="getCurrentApplication && getCurrentApplication.use_points === 'yes'">{{ entry.points }}</td>
          </tr>
          <tr v-else>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row" v-if="calcTypes && calcTypes.verbandstaetigkeit && options && options.verbandstaetigkeit">
    <div class="col-12">
      <div class="clearfix mt-5">
        <div class="row">
          <div class="col-md-6"><h3 class="mb-3">{{ options.verbandstaetigkeit.infos.title }}</h3></div>
          <div class="col-md-6">
            <button type="button" @click="showInput1 = 1" class="btn btn-secondary float-end mb-3"
                    v-if="$store.state.currentApplicationClosedState === false && showInput1 === 0">
              <font-awesome-icon :icon="['far', 'plus']"/>
              <span class="ms-3">Station hinzufügen</span></button>
          </div>
        </div>
      </div>
      <Modal class="inputBlock mb-3" v-if="showInput1 === 1">
        <div class="container">
          <div class="card p-lg-5 p-3">
            <h2 class="mb-4">{{ options.verbandstaetigkeit.infos.title }} hinzufügen</h2>
            <div class="description mb-4" v-html="options.verbandstaetigkeit.infos.description"></div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesNat.verband" class="form-control" placeholder="Verband">
                  <label for="floatingInput">Verband</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesNat.altersklasse" class="form-control" placeholder="Altersklasse">
                  <label for="floatingInput">Altersklasse</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesNat.liga" class="form-control" placeholder="Liga">
                  <label for="floatingInput">Liga</label>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <div class="form-floating mb-3">
                      <input type="date" :max="$store.state.maxDateValue" v-model="valuesNat.dateStart" class="form-control" placeholder="Beginn">
                      <label>Beginn</label>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="form-floating mb-3">
                      <input type="date" :max="$store.state.maxDateValue" v-model="valuesNat.dateEnd" class="form-control" placeholder="Ende">
                      <label>Ende</label>
                    </div>
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <input type="number" v-model="valuesNat.saisons" class="form-control" placeholder="Saisons" min="0" step="0.50" max="100">
                  <label for="floatingInput">Saisons</label>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="form-floating mb-3">
                  <perfect-scrollbar>
                  <div v-for="(option, key) in options.verbandstaetigkeit.options" v-bind:key="key">
                    <template v-if="option.is_headline === '1'">
                      <label class="labelHeadline"
                             style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                             :for="'option'+key">{{ option.question }}</label>
                    </template>
                    <template v-else>
                    <input :value="option.id" v-model="valuesNat.answer_id" type="radio" class="btn-check"
                           :id="'option'+key" autocomplete="off">
                    <label class="btn btn-outline-primary"
                           style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                           :for="'option'+key">{{ option.question }}</label>
                    </template>
                  </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-6">
                <button class="btn btn-danger mb-3" type="button" @click="showInput1 = 0">Abbrechen</button>
              </div>
              <div class="col-6">
                <button  v-if="valuesNat.answer_id && valuesNat.altersklasse && valuesNat.dateEnd && valuesNat.liga && valuesNat.verband && valuesNat.saisons"  @click="postValues('verbandstaetigkeit', valuesNat);showInput1 = 0" type="button"
                        class="btn btn-secondary float-end mb-3">Speichern
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div class="table-responsive">
        <table class="table table-striped table-values">
          <thead v-if="activeAnswers.verbandstaetigkeit">
          <tr>
            <th class="del"></th><th class="begin-end">Beginn</th>
            <th class="begin-end">Ende</th>

            <th>Verband</th>
            <th>Altersklasse</th>
            <th>Liga</th>
            <th>Station</th>
            <th>Saisons</th>
            <th class="text-center" style="width:130px">Nachweis</th>
            <th class="points-end" v-if="getCurrentApplication && getCurrentApplication.use_points === 'yes'">Punkte</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="entry in activeAnswers.verbandstaetigkeit.items" v-if="activeAnswers.verbandstaetigkeit">
            <td>
              <span v-if="$store.state.currentApplicationClosedState === false" class="delEntry btn btn-small btn-danger" @click='delEntry(entry.id)'>X</span>

              <EntryComment :entry="entry" />
            </td><td>{{ $filters.formatDate(entry.dateStart) }}

          </td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verband }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td>{{ entry.liga }}</td>
            <td>{{ entry.question }}</td>
            <td>{{ entry.saisons }}</td>

            <td >
              <template v-if="$store.state.currentApplicationClosedState === false && calcTypes && calcTypes.verbandstaetigkeit && calcTypes.verbandstaetigkeit.nachweis_erforderlich === 'yes'">
                <button @click="startedUpload = entry.id" class="btn w-100" :class="(entry.nachweis && entry.nachweis.length > 0) ? 'btn-warning': 'btn-danger'"><font-awesome-icon style="cursor: pointer" :icon="['far', 'plus']"/></button>                <template  v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span v-else> {{index+1}}</span>
                  </button>
                </template>
              </template>
            </td>
            <td  v-if="getCurrentApplication && getCurrentApplication.use_points === 'yes'">{{ entry.points }}</td>
          </tr>
          <tr v-else>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row" v-if="calcTypes && calcTypes.internationaltrainer && options && options.internationaltrainer">
    <div class="col-12">
      <div class="clearfix mt-5">
        <div class="row">
          <div class="col-md-6"><h3 class="mb-3">{{ options.internationaltrainer.infos.title }}</h3></div>
          <div class="col-md-6">
            <button type="button" @click="showInput2 = 1" class="btn btn-secondary float-end mb-3"
                    v-if="$store.state.currentApplicationClosedState === false && showInput2 === 0">
              <font-awesome-icon :icon="['far', 'plus']"/>
              <span class="ms-3">Station hinzufügen</span></button>
          </div>
        </div>
      </div>
      <Modal class="inputBlock mb-3" v-if="showInput2 === 1">
        <div class="container">
          <div class="card p-lg-5 p-3">
            <h2 class="mb-4">{{ options.internationaltrainer.infos.title }} hinzufügen</h2>
            <div class="description mb-4" v-html="options.internationaltrainer.infos.description"></div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesI.verband" class="form-control" placeholder="Verband">
                  <label for="floatingInput">Verband</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesI.altersklasse" class="form-control" placeholder="Altersklasse">
                  <label for="floatingInput">Altersklasse</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" v-model="valuesI.liga" class="form-control" placeholder="Liga">
                  <label for="floatingInput">Liga</label>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <div class="form-floating mb-3">
                      <input type="date" :max="$store.state.maxDateValue" v-model="valuesI.dateStart" class="form-control" placeholder="Beginn">
                      <label>Beginn</label>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="form-floating mb-3">
                      <input type="date" :max="$store.state.maxDateValue" v-model="valuesI.dateEnd" class="form-control" placeholder="Ende">
                      <label>Ende</label>
                    </div>
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <input type="number" v-model="valuesI.saisons" class="form-control" placeholder="Saisons" min="0" step="0.50" max="100">
                  <label for="floatingInput">Saisons</label>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="form-floating mb-3">
                  <perfect-scrollbar>
                    <div v-for="(option, key) in options.internationaltrainer.options" v-bind:key="key">
                      <template v-if="option.is_headline === '1'">
                        <label class="labelHeadline"
                               style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                               :for="'option'+key">{{ option.question }}</label>
                      </template>
                      <template v-else>
                      <input :value="option.id" v-model="valuesI.answer_id" type="radio" class="btn-check"
                             :id="'option'+key" autocomplete="off">
                      <label class="btn btn-outline-primary"
                             style="border-radius: 0 !important; text-align: left; width: 100%; margin-bottom: 14px;"
                             :for="'option'+key">{{ option.question }}</label>
                      </template>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-6">
                <button class="btn btn-danger mb-3" type="button" @click="showInput2 = 0">Abbrechen</button>
              </div>
              <div class="col-6">
                <button  v-if="valuesI.answer_id && valuesI.verband && valuesI.altersklasse && valuesI.liga && valuesI.dateStart && valuesI.dateEnd && valuesI.saisons"  @click="postValues('internationaltrainer', valuesI);showInput2 = 0" type="button"
                        class="btn btn-secondary float-end mb-3">Speichern
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div class="table-responsive">
        <table class="table table-striped table-values">
          <thead v-if="activeAnswers.internationaltrainer">
          <tr>
            <th class="del"></th><th class="begin-end">Beginn</th>
            <th class="begin-end">Ende</th>
            <th>Verband</th>
            <th>Altersklasse</th>
            <th>Liga</th>
            <th>Station</th>
            <th>Saisons</th>

            <th class="text-center" style="width:130px" >Nachweis</th>
            <th class="points-end"  v-if="getCurrentApplication && getCurrentApplication.use_points === 'yes'">Punkte</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="entry in activeAnswers.internationaltrainer.items" v-if="activeAnswers.internationaltrainer">
            <td>
              <span v-if="$store.state.currentApplicationClosedState === false" class="delEntry btn btn-small btn-danger" @click='delEntry(entry.id)'>X</span>
              <EntryComment :entry="entry" />
            </td><td>{{ $filters.formatDate(entry.dateStart) }}

          </td>
            <td>{{ $filters.formatDate(entry.dateEnd) }}</td>
            <td>{{ entry.verband }}</td>
            <td>{{ entry.altersklasse }}</td>
            <td>{{ entry.liga }}</td>
            <td>{{ entry.question }}</td>
            <td>{{ entry.saisons }}</td>
            <td v-if="calcTypes && calcTypes.internationaltrainer && calcTypes.internationaltrainer.nachweis_erforderlich === 'yes'">
              <template v-if="$store.state.currentApplicationClosedState === false && calcTypes && calcTypes.internationaltrainer && calcTypes.internationaltrainer.nachweis_erforderlich === 'yes'">
                <button @click="startedUpload = entry.id" class="btn w-100" :class="(entry.nachweis && entry.nachweis.length > 0) ? 'btn-warning': 'btn-danger'" ><font-awesome-icon style="cursor: pointer" :icon="['far', 'plus']"/></button>
                <template  v-for="(nw,index) in entry.nachweis">
                  <button @click="openFile(entry,index)" v-if="nw" type="button" class="bg-secondary"
                          style="font-size: 12px; padding:1px; width: 25px; border: 0; color:#fff; margin-right: 2px; margin-top: 4px;">
                    <span v-if="loadingFile === entry.id+index" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span v-else> {{index+1}}</span>
                  </button>
                </template>
              </template>
            </td>
            <td  v-if="getCurrentApplication && getCurrentApplication.use_points === 'yes'">{{ entry.points }}</td>
          </tr>
          <tr v-else>
            <td colspan="100%"><p>Noch keine Einträge vorhanden</p></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
    <Modal v-if="activeFile && activeFile.content">

      <div class="container">
        <div class="card" style="padding:24px; max-width: 80%">
          <div class="row">
            <div class="col-lg-6"><button @click="deleteFile()" class="btn btn-danger">Datei löschen</button></div>
            <div class="col-lg-6"><button @click="activeFile = null;" class="btn btn-success float-end">Vorschau schließen</button></div>
          </div>


          <perfect-scrollbar style="max-height: 80vh">
            <vue-pdf-embed v-if="activeFile.contentType === 'application/pdf'" :source="'data:'+activeFile.contentType+';base64,'+activeFile.content" type="" />
            <embed v-else :src="'data:'+activeFile.contentType+';base64,'+activeFile.content">
          </perfect-scrollbar>
        </div>
      </div>


    </Modal>
    <Modal v-if="startedUpload" >
      <div class="container">
        <div class="card" style="padding:24px; max-width: 80%">
          <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6"><button @click="startedUpload = null;" class="btn btn-success float-end">Upload schließen</button></div>
          </div>
          <UploadComponent :entryId="startedUpload"/>
        </div>
      </div>

    </Modal>
  </template>
</template>
<script>
import axios from "axios";
import {mapGetters, mapMutations} from "vuex";
import {ref} from "vue";
import UploadComponent from "@/components/UploadComponent";
import Preloader from "@/components/Preloader";
import {useToast} from "vue-toastification";
import VuePdfEmbed from "vue-pdf-embed";
import EntryComment from "@/components/application/EntryComment";
export default {
  name: 'ApplicationCoachExperiences',
  props: {
    calcTypes: {}
  },
  components: {
    UploadComponent,
    EntryComment,
    Preloader,
    VuePdfEmbed
  },
  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },
  data() {
    return {
      startedUpload: null,
      activeFile: null,
      nav: 1,
      options: null,
      loadingFile: null,
      punkte: 0,
      ergebnis: 0,
      values: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verein: null
      },
      valuesNat: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verband: null
      },
      valuesI: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verband: null
      },
      valuesSpieler: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verein: null
      },
      valuesSpielerNat: {
        answer_id: null,
        saisons: null,
        dateStart: null,
        dateEnd: null,
        liga: null,
        altersklasse: null,
        verband: null
      },
      valuesBildung: {
        answer_id: null,
        schwerpunkt: null,
        institution: null,
      },
      valuesWeiterbildung: {
        answer_id: null,
        schwerpunkt: null,
        institution: null,
      },
      activeCustomer: {},
      activeAnswers: null,
      showInput: 0,
      showInput1: 0,
      showInput2: 0,
      showInput4: 0,
      showInput42: 0,
      showInput5: 0,
      showInput6: 0
    }
  },
  computed: {
    ...mapGetters(["getUser", "getApiUrl", "getApiAuth", "getCurrentApplication"])
  },
  created() {
    this.getOptions()
    const headers = {"crmAuth": localStorage.getItem("dfbToken")};
    axios.get(this.getApiUrl + 'customer/' + this.getUser.crm_customer_id, {headers})
      .then(response => {
        let shortResponse = response.data.result;
        this.activeCustomer = shortResponse.data;
        if (this.activeCustomer.id) {
          this.getAnswers();
        }
      })
      .catch(error => {

      })
    window.addEventListener("triggerReloadTask", this.getAnswers)
  },
  methods: {
    startUploadInline(entryId) {
      this.startedUpload = entryId;
    },
    openFile(entry, index) {

      this.loadingFile = entry.id+index;
      const headers = {"crmAuth":localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'plugins/dfb/readFile/' + entry.id+'/'+index, {headers}).then((response) => {
        let shortReport = response.data.result;
        this.activeFile = {entryId: entry.id, index: index, content: shortReport.data.content, contentType: shortReport.data.contentType};
        this.loadingFile = null;
      }).catch((e) => {

        this.loadingFile = null;
      });
    },
    deleteFile() {


      this.deletingFile = this.activeFile.id;
      const headers = {"crmAuth":localStorage.getItem("dfbToken")};
      axios.delete(this.getApiUrl + 'plugins/dfb/removeFile/' + this.activeFile.entryId+'/'+this.activeFile.index, {headers}).then((response) => {
        this.deletingFile = null;
        this.activeFile = null;
        const triggerReloadTask = new CustomEvent('triggerReloadTask');
        window.dispatchEvent(triggerReloadTask);
      }).catch((e) => {

        this.deletingFile = null;
        this.activeFile = null;
        const triggerReloadTask = new CustomEvent('triggerReloadTask');
        window.dispatchEvent(triggerReloadTask);
      });
    },

    delEntry(entryId) {

      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.delete(this.getApiUrl + 'plugins/dfb/answers/' + entryId, {headers})
        .then(response => {
          const toast = useToast();
          toast.success("Eintrag wurde gelöscht");
          this.getAnswers();
        })
        .catch(error => {

        })
    },
    getAnswers() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.getApiUrl + 'plugins/dfb/answers?requestId='+this.$store.state.currentApplication.id, {headers})
        .then(response => {

          let shortResponse = response.data.result;

          this.activeAnswers = shortResponse.data
        })
        .catch(error => {

        })
    },
    showNav: function (index) {
      this.nav = index
    },
    postValues: function (postType, values) {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.post(this.getApiUrl + 'plugins/dfb/' + postType, values, {
        headers
      })
        .then(response => {

          let shortReport = response.data.result;
          this.activeAnswers = shortReport.data;
          this.showInput = 0;
          this.$store.dispatch('setToastsDone');
        })
        .catch(error => {
          this.$store.dispatch('handleApiError', error);
        })
    },
    getOptions: function () {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      axios.get(this.$store.state.apiUrl + 'plugins/dfb/config?requestId='+this.$store.state.currentApplication.id, {headers})
        .then(response => {
          this.options = response.data.result.data
        })
        .catch(error => {

        })
    }
  },
}
</script>
<style lang="scss">
.table-values {
  .btn-small {
    font-size: 10px;
    padding: 1px 5px !important;
  }
  th.begin-end {
    width: 85px;
  }
  th.points-end {
    width: 85px;
    text-align: right;
  }
  td {
    font-size: 14px;
    &:last-child {
      text-align: right;
    }
  }
}

</style>
