<template>
  <MainHeader headline="TEC-Zertifikat" :text="'Teilnehmer*innen nominieren'"/>
  <div class="container pt-4" style="min-height: 90vh">

    <!-- Add bootstrap form with firstname, lastname, email, phone -->
    <div class="row" v-if="view === 'default'">
      <div class="item-page" v-if="nomination && nomination.request && nomination.request.public_desc"
           v-html="nomination.request.public_desc">
      </div>
      <div class="col-12 col-md-12 col-lg-12 mb-1">
        <h4>Teilnehmer*innen nominieren</h4>

          <div class="mb-3">
            <label for="firstname" class="form-label">Vorname</label>
            <input type="text" class="form-control" id="firstname" v-model="save.c_firstname">
          </div>
          <div class="mb-3">
            <label for="lastname" class="form-label">Nachname</label>
            <input type="text" class="form-control" id="lastname" v-model="save.c_lastname">
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" class="form-control" id="email" v-model="save.c_email">
          </div>
          <div class="mb-3">
            <label for="phone" class="form-label">Leistungszentrum</label>
            <select type="text" class="form-select" v-model="save.structure1">
              <option value="" disabled>Bitte wählen</option>
              <option v-for="centre in nomination.structure" :value="centre.id">{{ centre.name }}</option>
            </select>
          </div>
          <button type="submit" @click="submitNomination" class="btn btn-primary mt-3">Nominieren</button>

      </div>
    </div>
    <div class="row" v-if="view === 'thanks'">
      <div class="col-12 col-md-12 col-lg-12 mb-1">
        <div class="thanksContainer" v-if="nomination.request.public_thanks" v-html="nomination.request.public_thanks"></div>

      </div>
    </div>
  </div>
</template>
<script>
import UserLogin from "@/components/UserLogin";
import MainHeader from "@/components/MainHeader.vue";
import axios from "axios";

export default {
  name: 'Nominierungen',
  data() {
    return {
      nomination: {},
      save: {
        c_firstname: "",
        c_lastname: "",
        c_email: "",
        structure1: ""
      },
      view: "default"
    }
  },
  components: {
    MainHeader,
    UserLogin
  },
  methods: {
    getInfos() {
      // get ?hash= from url
      let hash = this.$route.query.hash;
      if (hash) {
        axios.get(this.$store.state.apiUrl + "plugins/dfb/nomination?hash=" + hash)
          .then(response => {
            this.nomination = response.data.result.data;
          })
      } else {
        this.$router.push('/login');
      }
    },
    submitNomination() {
      axios.post(this.$store.state.apiUrl + "plugins/dfb/nomination", this.save)
        .then(response => {

            this.view = "thanks";


        })
    }
  },
  created() {
    this.getInfos();
  }
}
</script>
